.navbar {
  width: 100%;
  height: 100px;
}

.logo img {
  padding-left: 15px;
  object-fit: cover;
  height: 4rem;
  width: 5rem;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
  font-size: 22px;
  text-transform: uppercase;
  transition: color 0.3s ease, transform 0.2s ease;
}

.navbar a:hover {
  color: #fc46a8;
  transform: translateY(-2px);
}

.navbar a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #4af7ff;
  left: 0;
  bottom: -4px;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.navbar a:hover::after {
  transform: scaleX(1);
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}

.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

#open {
  height: 100vh;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .navbar a {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}
