.footer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.socialmedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.socialmedia svg:hover {
  color: #4af7ff;
}

.footer p {
  color: white;
}

b {
  color: #4af7ff;
}
